import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginUserInfoAction } from '../Redux/Action/ManageAdminAction';


const UserInfo = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    dispatch(LoginUserInfoAction()).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  const userInfo = useSelector((state) => state.manageAdminsSlice.userInfo);
  const permission = userInfo?.access?.full
  // const permission = true

  return { isLoading, userInfo, permission };
};

export default UserInfo;
